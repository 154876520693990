/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    h3: "h3",
    p: "p",
    img: "img",
    h1: "h1"
  }, _provideComponents(), props.components), {Hero, Columns, Column, List, Video, Typography, Media, Background, Testimonial, Link, LogoCloud, Form, Resource} = _components;
  if (!Background) _missingMdxReference("Background", true);
  if (!Column) _missingMdxReference("Column", true);
  if (!Columns) _missingMdxReference("Columns", true);
  if (!Form) _missingMdxReference("Form", true);
  if (!Hero) _missingMdxReference("Hero", true);
  if (!Link) _missingMdxReference("Link", true);
  if (!List) _missingMdxReference("List", true);
  if (!LogoCloud) _missingMdxReference("LogoCloud", true);
  if (!Media) _missingMdxReference("Media", true);
  if (!Resource) _missingMdxReference("Resource", true);
  if (!Testimonial) _missingMdxReference("Testimonial", true);
  if (!Typography) _missingMdxReference("Typography", true);
  if (!Video) _missingMdxReference("Video", true);
  return React.createElement(React.Fragment, null, React.createElement(Hero, {
    background: "deepPurple",
    backgroundImage: "primary",
    color: "white01",
    headline: "The Embedded iPaaS for B2B SaaS",
    text: "Launch powerful native integrations fast with the embedded integration platform built to handle real-world complexity."
  }), "\n", React.createElement(Columns, {
    spacing: "large"
  }, React.createElement(Column, {
    sm: 6
  }, React.createElement(_components.h2, {
    id: "saas-companies-prefer-prismatic",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#saas-companies-prefer-prismatic",
    "aria-label": "saas companies prefer prismatic permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "SaaS companies prefer Prismatic"), React.createElement(_components.h3, {
    id: "the-embedded-ipaas-for-b2b-saas",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#the-embedded-ipaas-for-b2b-saas",
    "aria-label": "the embedded ipaas for b2b saas permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "The embedded iPaaS for B2B SaaS:"), React.createElement(List, {
    variant: "check"
  }, React.createElement("li", null, "Built from day one for B2B SaaS teams"), React.createElement("li", null, "Flexible dev tools that fit into your pipeline"), React.createElement("li", null, "Code powerful custom connectors to any app"), React.createElement("li", null, "Start building and deploying integrations fast"))), React.createElement(Column, {
    sm: 6
  }, React.createElement(Video, {
    image: "/images/landing-page/video-thumbnail.png",
    videoId: "488710278"
  }))), "\n", React.createElement(Typography, {
    align: "center",
    variant: "h2",
    spacing: "medium"
  }, React.createElement(_components.p, null, "Essential Features")), "\n", React.createElement(Columns, {
    spacing: "small"
  }, React.createElement(Media, {
    title: "Low-Code Integration Designer",
    image: "/images/landing-page/integration-icon.png",
    imageBorder: false
  }), React.createElement(Media, {
    title: "Embedded Integration Marketplace",
    image: "/images/landing-page/embeddable-ux-icon.png",
    imageBorder: false
  }), React.createElement(Media, {
    title: "Pre-built and Custom Connectors",
    image: "/images/landing-page/components-icon.png",
    imageBorder: false
  })), "\n", React.createElement(Background, {
    background: "gray03",
    color: "gray09",
    fullWidth: true,
    spacing: "medium"
  }, React.createElement(Testimonial, {
    id: "cyrils"
  })), "\n", React.createElement(Typography, {
    align: "center",
    variant: "h4",
    spacing: "large"
  }, React.createElement(_components.p, null, "Purpose-built for Native SaaS Integrations")), "\n", React.createElement(Columns, {
    spacing: "large"
  }, React.createElement(Column, {
    sm: 4
  }, React.createElement(_components.h3, {
    id: "brilliant-workflows",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#brilliant-workflows",
    "aria-label": "brilliant workflows permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Brilliant Workflows"), React.createElement(_components.p, null, "Build integrations with a low-code designer. Add complex business logic and configuration variables."), React.createElement(Link, {
    href: "/platform/integration-designer",
    label: "See How",
    color: "secondary"
  })), React.createElement(Column, {
    sm: 4
  }, React.createElement(_components.h3, {
    id: "seamless-embedding",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#seamless-embedding",
    "aria-label": "seamless embedding permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Seamless Embedding"), React.createElement(_components.p, null, "Add native integrations in a white-labeled marketplace that fits your product and improves your CX."), React.createElement(Link, {
    href: "/platform/embedded-integration-marketplace",
    label: "See How",
    color: "secondary"
  })), React.createElement(Column, {
    sm: 4
  }, React.createElement(_components.h3, {
    id: "limitless-connectors",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#limitless-connectors",
    "aria-label": "limitless connectors permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Limitless Connectors"), React.createElement(_components.p, null, "Connect to literally anything. Devs love to build with our powerful Node.js custom connector SDK."), React.createElement(Link, {
    href: "/platform/built-in-a-way-devs-love",
    label: "See How",
    color: "secondary"
  }))), "\n", React.createElement(Typography, {
    align: "center",
    variant: "h2"
  }, React.createElement(_components.p, null, "Scale Your Integration Strategy Fast with Prismatic")), "\n", React.createElement(Columns, {
    spacing: "large"
  }, React.createElement(Column, {
    sm: 6
  }, React.createElement(_components.h2, {
    id: "flexible-integration-designer",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#flexible-integration-designer",
    "aria-label": "flexible integration designer permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Flexible Integration Designer"), React.createElement(_components.h3, {
    id: "combine-pre-built-and-custom-connectors-to-build-powerful-workflows",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#combine-pre-built-and-custom-connectors-to-build-powerful-workflows",
    "aria-label": "combine pre built and custom connectors to build powerful workflows permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Combine pre-built and custom connectors to build powerful workflows"), React.createElement(_components.p, null, "The intuitive low-code designer empowers non-devs to create integrations by assembling connectors and other components."), React.createElement(_components.p, null, "And, devs can write code with our Node.js SDK to create custom connectors to any third-party app your customers use.")), React.createElement(Column, {
    sm: 6
  }, React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "/images/landing-page/integration-designer.png",
    alt: "Integration Designer"
  })))), "\n", React.createElement(Columns, {
    spacing: "large"
  }, React.createElement(Column, {
    sm: 6
  }, React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "/images/landing-page/self-activation.png",
    alt: "GraphQL API Explorer"
  }))), React.createElement(Column, {
    sm: 6
  }, React.createElement(_components.h2, {
    id: "reusable-configurable-integrations",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#reusable-configurable-integrations",
    "aria-label": "reusable configurable integrations permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Reusable, Configurable Integrations"), React.createElement(_components.h3, {
    id: "scale-your-integration-strategy-faster-with-customizable-integrations",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#scale-your-integration-strategy-faster-with-customizable-integrations",
    "aria-label": "scale your integration strategy faster with customizable integrations permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Scale your integration strategy faster with customizable integrations"), React.createElement(_components.p, null, "Use powerful configuration variables in your integrations to make them reusable. Each integration can behave differently across your customer base."), React.createElement(_components.p, null, "A config UX makes it easy for customer-facing teams to deploy integrations, and for customers to self-activate integrations in your integration marketplace."))), "\n", React.createElement(Columns, {
    spacing: "large"
  }, React.createElement(Column, {
    sm: 6
  }, React.createElement(_components.h2, {
    id: "embedded-integration-marketplace",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#embedded-integration-marketplace",
    "aria-label": "embedded integration marketplace permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Embedded Integration Marketplace"), React.createElement(_components.h3, {
    id: "surface-integrations-natively-in-your-product-so-your-customers-can-self-serve",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#surface-integrations-natively-in-your-product-so-your-customers-can-self-serve",
    "aria-label": "surface integrations natively in your product so your customers can self serve permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Surface integrations natively in your product so your customers can self-serve"), React.createElement(_components.p, null, "Embed a white-labeled integration marketplace in your app that looks and feels like part of your product. Enable your customers to discover and manage their own unique integrations, and configure them to work how they want.")), React.createElement(Column, {
    sm: 6
  }, React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "/images/landing-page/embedded-integration-marketplace.png",
    alt: "Embedded Integration Marketplace image"
  })))), "\n", React.createElement(Background, {
    background: "gray03",
    color: "gray09",
    fullWidth: true,
    spacing: "medium"
  }, React.createElement(Testimonial, {
    id: "pames2"
  })), "\n", React.createElement(Typography, {
    align: "center",
    variant: "h3"
  }, React.createElement(_components.p, null, "Trusted by product and dev teams from startups to Fortune 100")), "\n", React.createElement(LogoCloud, {
    logos: "/images/logo-big-tin-can.svg,/images/logo-elastic.svg,/images/logo-sisu.svg,/images/logo-raven-industries.svg,/images/logo-sound-thinking.svg,/images/logo-ingenious.svg,/images/logo-hatch.svg,/images/logo-deepstream.svg",
    fullWidth: true
  }), "\n", React.createElement(Background, {
    backgroundImage: "dark",
    background: "deepPurple",
    color: "white01",
    fullWidth: true,
    spacing: "medium"
  }, React.createElement(Columns, null, React.createElement(Column, {
    sm: 6
  }, React.createElement(_components.h1, {
    id: "request-your-demo",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#request-your-demo",
    "aria-label": "request your demo permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Request Your Demo"), React.createElement(_components.p, null, "We'd love to show you why Prismatic is the leading embedded integration platform. Loved by product leaders and engineering teams alike, Prismatic helps you:"), React.createElement(List, {
    variant: "check"
  }, React.createElement("li", null, "Launch integrations in hours, not months"), React.createElement("li", null, "Build complex integrations to any application"), React.createElement("li", null, "Drive user adoption and reduce churn"), React.createElement("li", null, "Increase time for core product work")), React.createElement(Columns, {
    spacing: "large"
  }, React.createElement(Media, {
    image: "/images/landing-page/g2-badge-1.png",
    imageBorder: false
  }), React.createElement(Media, {
    image: "/images/landing-page/g2-badge-2.png",
    imageBorder: false
  }), React.createElement(Media, {
    image: "/images/landing-page/g2-badge-3.png",
    imageBorder: false
  }), React.createElement(Media, {
    image: "/images/landing-page/g2-badge-4.png",
    imageBorder: false
  }))), React.createElement(Column, {
    sm: 6
  }, React.createElement(Form, {
    name: "embedded-demo",
    spacing: "medium"
  })))), "\n", React.createElement(Typography, {
    align: "center",
    variant: "h4",
    textTransform: "uppercase"
  }, React.createElement(_components.p, null, "Resources")), "\n", React.createElement(Columns, {
    spacing: "medium",
    gap: 3
  }, React.createElement(Resource, {
    backgroundImage: "card-medium-light-v1",
    description: "Learn all about embedded iPaaS: what it is, its benefits, and how to choose the right one for your team.",
    link: "/resources/embedded-ipaas-scalable-integration-strategy/",
    color: "gray12",
    overline: "Guides",
    title: "Embedded iPaaS: The Integration Strategy for B2B Software Companies"
  }), React.createElement(Resource, {
    backgroundImage: "card-large-dark-v1",
    description: "Native integrations improve the customer experience for B2B software companies. Should you build or buy?",
    link: "/resources/build-vs-buy-adding-native-integrations-to-your-product/",
    overline: "Guides",
    title: "Native Integration: A Guide to Building vs Buying Native Integrations"
  })));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
